<template>
  <div class="wrapper">
    <div class="left" v-loading="roleLoading">
      <div class="title">
        <div class="title-txt">职位列表</div>
        <el-button type="primary" size="mini" @click="handleAdd"
          >添加职位</el-button
        >
      </div>
      <div class="scroll">
        <template v-for="(item, index) in list">
          <div
            class="left-list"
            :key="item.id"
            @click="handleClick(item, index)"
          >
            <div
              class="left-list-item"
              :class="current.id === item.id ? 'left-list-item-active' : ''"
            >
              <div class="name" :style="item.hierarchy_id === '1' ? {} : {}">
                {{ item.title }}
                <span v-if="item.hierarchy_id !== '1'"
                  >({{ item.hierarchy_name }})</span
                >
                <span
                  v-if="item.hierarchy_id === '1'"
                  style="margin-left: 5px; color: #ccc; font-size: 12px"
                  >默认角色</span
                >
              </div>
              <div
                class="edit"
                v-if="current.hierarchy_id !== '1'"
                @click="handleEdit(item)"
                :style="current.id === item.id ? { display: 'block' } : ''"
              >
                编辑
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="right" v-loading="roleLoading">
      <div class="title">
        <div class="title-txt">权限配置</div>
        <el-button type="primary" size="mini" @click="handleSave"
          >保存</el-button
        >
      </div>
      <div class="scroll">
        <!-- <Check :list.sync="treeData" /> -->
        <el-tree
          class="tree-line"
          :indent="0"
          ref="tree"
          :data="treeData"
          show-checkbox
          node-key="id"
          :default-expanded-keys="expandedKeys"
          :props="defaultProps"
          :check-strictly="true"
          :default-expand-all="false"
          :expand-on-click-node="false"
          :check-on-click-node="true"
          accordion
        >
          <div slot-scope="{ node, data }">
            <div>
              {{ node.label
              }}<el-button
                type="primary"
                style="margin-left: 5px"
                size="mini"
                v-if="data.level === 4 && data.have_field === 10"
                @click="() => handleRoleField(data)"
                >编辑字段权限</el-button
              >
            </div>
          </div>
        </el-tree>
      </div>
    </div>
    <el-dialog
      :title="form.id ? '编辑职位' : '添加职位'"
      :visible.sync="dialogVisible"
      :destroy-on-close="true"
      :before-close="handleClose"
      width="30%"
    >
      <el-form
        :model="form"
        label-width="120px"
        :tree="tree"
        ref="form"
        :rules="rules"
      >
        <el-form-item label="职位名称" required prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item
          label="选择所属门店"
          required
          prop="hierarchy_id"
          v-if="userInfo.hierarchy_type_id === 30"
        >
          <el-select v-model="form.hierarchy_id">
            <template v-for="item in shopList">
              <el-option
                :key="item.hierarchy_id"
                :label="item.hierarchy_name"
                :value="item.hierarchy_id"
              ></el-option>
            </template>
          </el-select>
        </el-form-item>
      </el-form>
      <div style="text-align: right; margin-top: 15px">
        <el-button type="primary" @click="submitForm('form')" :loading="loading"
          >确定</el-button
        >
        <el-button @click="handleClose">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="'设置' + fieldName + '字段权限'"
      :visible.sync="fieldVisible"
      :destroy-on-close="true"
      :before-close="handleCloseField"
      width="30%"
    >
      <el-form :model="fieldForm" label-width="120px" ref="form">
        <template v-for="(item, index) in fieldList">
          <el-form-item
            :label="item.field_value"
            :prop="item.field_key"
            :key="index"
          >
            <el-checkbox v-model="item.able">显示</el-checkbox>
          </el-form-item>
        </template>
      </el-form>
      <div style="text-align: right">
        <el-button @click="handleCloseField">取消</el-button>
        <el-button
          type="primary"
          @click="handleCommitField"
          :loading="fieldLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  createReq,
  listReq,
  editReq,
  menuListReq,
  setRoleReq,
  roleConfReq,
  getMerchantListReq,
  roleField,
  setRoleField,
} from "@/api/authority/config";
import storage from "good-storage";
import Check from "./Check.vue";

export default {
  name: "Config",
  data() {
    return {
      index: 0,
      roleLoading: false,
      userInfo: {},
      shopList: [],
      childrenKeys: {}, // 所有儿子id,传给后端的tree只传儿子Id
      checkedKeys: [],
      expandedKeys: [],
      checkList: [],
      treeData: [],
      loading3: false,
      current: {},
      list: [],
      loading2: false,
      loading: false,
      form: {},
      dialogVisible: false,
      auth_rule_id: 0,
      fieldName: "",
      fieldVisible: false,
      fieldLoading: false,
      fieldList: [],
      fieldForm: {},
      // data: [
      //   {
      //     id: 0, // 唯一id，必填
      //     path: "/index", //  路径，页面必填，具体权限为空
      //     meta: {
      //       title: "系统", // 标题，必填
      //       key: "0", // key值，页面必填，具体权限为空, key规则，0, 0-0, 0-0-0; 1, 1-0, 1-0-0;
      //       icon: "icon_home", // 只有最外面一级需要icon
      //     },
      //     children: [
      //       {
      //         id: 2,
      //         path: "/index",
      //         meta: {
      //           title: "权限管理",
      //           key: "0-0",
      //         },
      //         children: [
      //           {
      //             id: 3,
      //             path: "/xx",
      //             meta: {
      //               key: "0-0-0",
      //               title: "权限列表",
      //             },
      //             children: [
      //               {
      //                 id: 4,
      //                 path: "", // 具体权限不需要路径
      //                 meta: {
      //                   key: "", // 具体权限不需要key
      //                   title: "创建商家",
      //                 },
      //               },
      //             ],
      //           },
      //           {
      //             id: 5,
      //             path: "/xxx",
      //             meta: {
      //               key: "0-0-1",
      //               title: "统计列表",
      //             },
      //           },
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     id: 1,
      //     path: "/index",
      //     meta: {
      //       title: "导出商家",
      //       key: "1",
      //       icon: "icon_home",
      //     },
      //   },
      // ],
      defaultProps: {
        children: "children",
        label: "title",
      },
      tree: [],
      rules: {
        title: [{ required: true, message: "请输入职位名称" }],
        hierarchy_id: [{ required: true, message: "请选择所属门店" }],
      },
    };
  },
  watch: {
    current: {
      handler(val) {
        if (val.hierarchy_id === "1") {
          // this.handleTreeData(this.treeData, true);
          // 树状禁用
        } else {
          // 树状恢复
        }
      },
    },
  },
  components: {
    Check,
  },
  activated() {
    // this.getList();
    // this.getMenuList();
    const userInfo = storage.get("userInfo");
    this.userInfo = userInfo;
    this.getRoleConf();
    this.getMerchantList();
   
  },
  methods: {
    handleTreeData(arr, boolean) {
      function poling(arr, boolean) {
        arr.forEach((item) => {
          item.disabled = boolean;
          if (item.children) {
            poling(arr, boolean);
          }
        });
      }
      poling(arr, boolean);
    },
    getMerchantList() {
      getMerchantListReq().then((res) => {
        if (res.code === 1) {
          this.shopList = res.data;
        }
      });
    },
    /*
     *  获取职位列表,权限配置数据
     */
    getRoleConf() {
      this.roleLoading = true;
      roleConfReq()
        .then((res) => {
          if (res.code === 1) {
            this.roleLoading = false;
            if (res.data.role_list.length) {
              // eslint-disable-next-line prefer-destructuring
              this.current = res.data.role_list[this.index];
              let newArr = [];
              if (this.current.rules) {
                newArr = this.current.rules.split(",").map(Number);
              }
              this.$refs.tree.setCheckedKeys(newArr);
            }
            this.handleMenuData(res.data.menu_list);
            this.treeData = res.data.menu_list;
            this.list = res.data.role_list;
          }
        })
        .catch(() => {
          this.roleLoading = false;
        });
    },
    handleSave() {
      const role = this.$refs.tree.getCheckedKeys();
      const newRole = [];
      role.forEach((item) => {
        if (this.childrenKeys[item]) {
          newRole.push(item);
        }
      });
      setRoleReq({ id: this.current.id, role, tree: role }).then((res) => {
        if (res.code === 1) {
          this.$message.success("设置成功");
          this.list.forEach((item) => {
            if (item.id === this.current.id) {
              item.tree = role.join(",");
            }
          });
          this.getRoleConf();
        }
      });
    },
    handleMenuData(arr) {
      // let expandedKeys = [];
      // let checkedKeys = [];
      // this.expandedKeys = [1000, 155, 162, 192, 199, 255, 273, 157, 262];
      arr.forEach((item) => {
        if (item.level === 2 || item.level === 3) {
          this.expandedKeys.push(item.id);
        }
        if (item.children) {
          this.handleMenuData(item.children);
        } else {
          this.childrenKeys[item.id] = true;
        }
      });
    },
    getMenuList() {
      this.loading3 = true;
      menuListReq()
        .then((res) => {
          if (res.code === 1) {
            this.loading3 = false;
            this.handleMenuData(res.data);
            this.treeData = res.data;
          }
        })
        .catch(() => {
          this.loading3 = false;
        });
    },
    handleClick(item, index) {
      this.index = index;
      this.current = { ...item };
      if (this.current.tree) {
        const arr = this.current.tree.split(",");
        // eslint-disable-next-line no-shadow
        const newArr = arr.map((item) => Number(item));
        this.$refs.tree.setCheckedKeys(newArr);
      } else {
        this.$refs.tree.setCheckedKeys([]);
      }
    },
    handleEdit(item) {
      this.current = { ...item };
      this.form = { ...item };
      if (item.hierarchy_id === "1") {
        this.$message.error("不可编辑");
        return;
      }
      this.dialogVisible = true;
    },
    getList() {
      this.loading2 = true;
      listReq()
        .then((res) => {
          if (res.code === 1) {
            this.loading2 = false;
            if (res.data.length) {
              this.current = res.data[0];
              if (this.current.tree) {
                this.checkedKeys = this.current.tree.split(",").map(Number);
              }
              this.$refs.tree.setCheckedKeys(this.checkedKeys);
            }
            this.list = res.data;
          }
        })
        .catch(() => {
          this.loading2 = false;
        });
    },
    handleClose() {
      this.dialogVisible = false;
      this.form = {};
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (!this.form.id) {
            createReq(this.form)
              .then((res) => {
                if (res.code === 1) {
                  this.loading = false;
                  this.$message.success("操作成功");
                  this.handleClose();
                  this.getList();
                }
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            editReq(this.form)
              .then((res) => {
                if (res.code === 1) {
                  this.loading = false;
                  this.$message.success("操作成功");
                  this.handleClose();
                  this.getList();
                }
              })
              .catch(() => {
                this.loading = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    handleAdd() {
      this.dialogVisible = true;
    },
    handleCloseField() {
      this.fieldVisible = false;
      this.fieldList = [];
    },
    /*
     *  获取角色字段权限
     */
    handleRoleField(data) {
      this.fieldName = data.title;
      this.auth_rule_id = data.id;
      const info = {
        store_auth_group_id: this.current.id,
        auth_rule_id: data.id,
      };
      roleField(info).then((res) => {
        if (res.code === 1) {
          if (res.data.length !== 0) {
            this.fieldList = res.data;
            this.fieldVisible = true;
          }
        }
      });
    },
    /*
     *  设置角色字段权限
     */
    handleCommitField() {
      this.fieldLoading = true;
      const field_role = [];
      this.fieldList.forEach((item) => {
        if (item.able === true) {
          field_role.push(item.auth_rule_field_id);
        }
      });
      const data = {
        store_auth_group_id: this.current.id,
        auth_rule_id: this.auth_rule_id,
        field_role,
      };
      setRoleField(data)
        .then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.fieldVisible = false;
            this.fieldList = [];
            this.fieldLoading = false;
          } else {
            this.fieldLoading = false;
          }
        })
        .catch((res) => {
          this.fieldLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="less">
.scroll {
  height: calc(100vh - 150px);
  overflow: auto;
}
.title {
  /deep/ .el-button--primary {
    background-color: #409eff;
    border-color: #409eff;
    height: 32px;
  }
}

/deep/ .el-tree-node__label {
  font-size: 14px;
  &:nth-child(1) {
    font-weight: bold !important;
  }
}

/deep/ .el-tree-node__content {
  height: 40px;
  line-height: 40px;
}
/deep/
  .el-tree-node__children
  .el-tree-node__children
  .el-tree-node__children
  .el-tree-node__children {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .el-tree-node {
    width: 20%;
  }
  .el-tree-node::after {
    border-top: none;
  }
  .el-tree-node::before {
    border-left: none;
  }
}
.wrapper {
  display: flex;
  .title {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border-bottom: 1px solid #f5f6fa;
    &-txt {
      font-size: 16px;
      text-indent: 15px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 4px;
        height: 16px;
        background: #5494f5;
        border-radius: 2px;
        top: 5px;
        left: 0px;
      }
    }
  }
  .left {
    width: 460px;
    min-height: calc(100vh - 85px);
    margin-right: 10px;
    background: white;
    font-size: 14px;
    &-list {
      :hover {
        background: #f0f6fe;
        .edit {
          display: block;
        }
      }
      &-item {
        line-height: 50px;
        padding: 0 20px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        &-active {
          border: 1px solid #5494f5;
          background: #f0f6fe;
        }
        .name {
          width: 350px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .edit {
          color: #5494f5;
          display: none;
        }
      }
    }
  }
  .right {
    flex: 1;
    background: white;
    height: calc(100vh - 85px);
  }
}
/deep/ .el-form-item {
  margin-bottom: 0px !important;
}
</style>
