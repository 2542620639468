<template>
  <div class="wrapper">
    <template v-for="(item, index) in list">
      <div :key="index">
        <div
          v-if="item.children"
          style="
            background: #f5f7fa;
            height: 35px;
            line-height: 35px;
          "
        >
          <span
            class="el-tree-node__expand-icon el-icon-caret-right"
            style="font-size: 16px; margin-right: 5px"
          ></span>
          <el-checkbox :label="item.id" @change="handleChange(item)" class="txt"
            ><span style="font-size: 15px">{{ item.title }}</span></el-checkbox
          >
        </div>
        <div v-if="item.children">
          <Check v-if="item.children" :list.sync="item.children" />
        </div>
      </div>
    </template>
    <el-checkbox-group v-model="checkList" style="margin-left: 15px">
      <template v-for="(item, index) in list">
        <el-checkbox
          :key="index"
          :label="item.id"
          v-if="!item.children"
          style="margin-bottom: 10px"
          ><span style="font-size: 15px">{{ item.title }}</span></el-checkbox
        >
      </template>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  name: "Check",
  data() {
    return {
      checkList: [],
      isAll: false,
    };
  },
  props: {
    list: Array,
  },
  methods: {
    handleCheck() {
      console.log(this.list);
    },
    handleHeight() {
      this.isAll = true;
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  margin-left: 13px;
  .title {
    line-height: 30px;
  }
  /deep/ .el-checkbox {
    width: 200px;
  }
}
.txt {
  position: relative;
}
// .txt::before {
//   content: "";
//   height: 100%;
//   width: 1px;
//   position: absolute;
//   left: -15px;
//   top: -20px;
//   border-width: 1px;
//   border-left: 1px dashed #52627c;
// }
// .txt:after {
//   content: "";
//   width: 24px;
//   height: 20px;
//   position: absolute;
//   left: -3px;
//   top: 70px;
//   border-width: 1px;
//   border-top: 1px dashed #52627c;
// }
</style>
